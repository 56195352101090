/*
 * Servizio che gestisce i filtri della barra di ricerca
*/

import { Tag } from "atfcore-commonclasses";

class SearchParams {
    searchedText: string;
    myActivities: boolean;
    forMe: boolean;
    sorting: any;
    openedTab: number;
    openedSubTabLibrary: number;
    openedSubTabLibraryFilters: number;
    forceSearchSuggestedItems: boolean;
    forceMyActivitiesSearch: boolean;
    forceForMeSearch: boolean;
    subTabForMeSelected: number;
    subTabSelected: number;
    subTabTemplatesSelected: number;
    fromAdmin?: boolean;
}

export interface Filter {
    code: string;
    headerTitle: string;
    selectedTitle: string;
    headerSubTitle: string;
    exampleLabel: string;
    hasLettersDivision?: boolean;
    indexLetter?: number;
    atLeastOneSelected?: boolean;
    noContentFoundLabel?: string;
    isExpanded?: boolean;
    containerClass?: string;
    list?: Tag[];
    selectedFilters?: any[];
    selectedTechSkill?: any[];
    selectedSoftSkill?: any[];
    competence?: any;
    status?: any;
    customTabIcon?: string;
    clusterTitleCSS?: string;
    tagsToSelectLabel?: string;
    tagsInTotalLabel?: string;
    typeAlreadySelectedClass?: string;
    statusList?: any[];
    approachList?: any[];
    lettersDivisionContainer?: string[];
}

class Filters {
    [code: string]: Filter;
}

module SearchBarManager {
    let app = angular.module("app");

    app.service("SearchBarManager", function ($resource: ng.resource.IResourceService,
        GlobalApplicationData: any,
        $translate: angular.translate.ITranslateService,
        LibraryApplicationData: any,
        TagManager: any,
        SessionStorageService: any,
        $stateParams: any) {
        // Indice per il recupero delle traduzioni
        const INDEX_TRANSLATION_FILTERS = "librarySubFilters";
        const INDEX_TRANSLATION_ITEM_SORTING = "home.sorting";
        const INDEX_TRANSLATION_ITEM_STATUS = "home.tabs.filters.select";

        // Elenco di filtri disponibili per le relative pagine
        const filtersCategories: any = {
            "HOME_FILTERS": [LibraryApplicationData.constants.ARGUMENTS, LibraryApplicationData.constants.CLUSTERS, LibraryApplicationData.constants.FUNCTIONAL_AREAS, LibraryApplicationData.constants.LEVELS, LibraryApplicationData.constants.TYPE, LibraryApplicationData.constants.STATUS, LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS, LibraryApplicationData.constants.STREAMS, LibraryApplicationData.constants.LANG],
            "HOME_ADMIN_FILTERS": [LibraryApplicationData.constants.ARGUMENTS, LibraryApplicationData.constants.CLUSTERS, LibraryApplicationData.constants.FUNCTIONAL_AREAS, LibraryApplicationData.constants.LEVELS, LibraryApplicationData.constants.TYPE, LibraryApplicationData.constants.STATUS, LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS, LibraryApplicationData.constants.STREAMS, LibraryApplicationData.constants.LANG, LibraryApplicationData.constants.ADMIN_TAGS]
        }

        // Elenco di possibili stati da applicare nei filtri
        let statusList: Array<any> = [
            { id: 1, name: $translate.instant(INDEX_TRANSLATION_ITEM_STATUS + ".ALL") },
            { id: 2, name: $translate.instant(INDEX_TRANSLATION_ITEM_STATUS + ".select.VIEWED") },
            { id: 3, name: $translate.instant(INDEX_TRANSLATION_ITEM_STATUS + ".select.NOT_VIEWED") }
        ];

        // Elenco di ordinamenti disponibili per i filtri
        let ITEM_SORTING = [
            { id: 1, label: "S", name: $translate.instant(INDEX_TRANSLATION_ITEM_SORTING + ".SCORE") },
            { id: 2, label: "T", name: $translate.instant(INDEX_TRANSLATION_ITEM_SORTING + ".TITLE"), type: 'title' },
            { id: 3, label: "D", name: $translate.instant(INDEX_TRANSLATION_ITEM_SORTING + ".DATE"), type: 'creationDate' }
        ];

        // Elenco di approcci disponibili per i filtri (tech o soft)
        let competenceList = [
            { id: 1, name: $translate.instant(INDEX_TRANSLATION_ITEM_STATUS + ".ALL") },
            { id: 2, name: $translate.instant(INDEX_TRANSLATION_ITEM_STATUS + ".TECH_SKILL") },
            { id: 3, name: $translate.instant(INDEX_TRANSLATION_ITEM_STATUS + ".SOFT_SKILL") }
        ];

        // Elenco di approcci disponibili per il front end
        let competenceListForFrontEnd = [
            { id: 3, name: $translate.instant(INDEX_TRANSLATION_FILTERS + ".S_SKILLS"), setFilterAs: LibraryApplicationData.constants.SOFT_SKILL },
            { id: 2, name: $translate.instant(INDEX_TRANSLATION_FILTERS + ".T_SKILLS"), setFilterAs: LibraryApplicationData.constants.TECH_SKILL }
        ];

        // Elenco di status disponibili per il front end
        let statusListForFrontEnd = [
            { id: 2, name: $translate.instant(INDEX_TRANSLATION_FILTERS + ".ITEM_ALREADY_VIEWED"), setFilterAs: LibraryApplicationData.constants.VIEWED },
            { id: 3, name: $translate.instant(INDEX_TRANSLATION_FILTERS + ".ITEM_NOT_VIEWED"), setFilterAs: LibraryApplicationData.constants.NOT_VIEWED }
        ];

        // Parametri generali per la ricerca
        let searchParams: SearchParams = null;
        let sessionStorageObj: any = null;
        let filterParams: any = null;
        let filters: any = null;
        let filtersList = <Filters>null; // Cast per l'interfaccia

        // In base all'indice, recupera i filtri precedentemente scelti
        this.getStoredFilters = (dontManageHistory?: boolean): any => {
            let searchId = $stateParams.searchId = $stateParams.searchId && $stateParams.searchId > 0 ? $stateParams.searchId : 0;
            if (searchId && searchId > 0 && !dontManageHistory) {
                // Se sono in una pagina di ricerca degli item nello $stateParams c'è il searchId e questo rimane invariato, quindi il SearchBarManager si va a recuperare i filtri salvati in sessione. In tal caso, vengono recuperati i filtri nella lingua precedentemente scelta. Aggiorno quindi le etichette nella lingua selezionata
                this.setTabsTranslations();
                sessionStorageObj = SessionStorageService.getObject(searchId);
                filterParams = sessionStorageObj && sessionStorageObj.filterParams;
                filters = sessionStorageObj && sessionStorageObj.filters;
            } else {
                searchParams = null;
                sessionStorageObj = null;
                filterParams = null;
                filters = null;
                filtersList = null;
            }

            if (filters && filterParams && !dontManageHistory) {
                filtersList = filters;
                searchParams = filterParams;
            } else {
                filtersList = {};
                filtersList[LibraryApplicationData.constants.ARGUMENTS] = { code: LibraryApplicationData.constants.ARGUMENTS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.CATEGORIES'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.CATEGORIES'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".CATEGORY_EXAMPLE"), hasLettersDivision: true, indexLetter: 0, atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_CATEGORY_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], customTabIcon: '&#xE8E9;', tagsToSelectLabel: null, tagsInTotalLabel: null, lettersDivisionContainer: LibraryApplicationData.constants.LETTERS_DIVISION_DISPLAYED };
                filtersList[LibraryApplicationData.constants.CLUSTERS] = { code: LibraryApplicationData.constants.CLUSTERS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.CLUSTERS'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.CLUSTERS_SELECTED'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".CLUSTERS_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_CLUSTER_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], clusterTitleCSS: null, tagsToSelectLabel: $translate.instant("librarySubFilters.CLUSTERS_TO_SELECT"), tagsInTotalLabel: null };
                filtersList[LibraryApplicationData.constants.FUNCTIONAL_AREAS] = { code: LibraryApplicationData.constants.FUNCTIONAL_AREAS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.FUNCTIONAL_AREAS'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.FUNCTIONAL_AREAS_SELECTED'), exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".FUNCTIONAL_AREAS_EXAMPLE"), headerSubTitle: null, hasLettersDivision: true, indexLetter: 0, atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_FUNCTIONAL_AREA_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], tagsToSelectLabel: $translate.instant("librarySubFilters.FUNCTIONAL_AREAS_TO_SELECT"), tagsInTotalLabel: null, lettersDivisionContainer: LibraryApplicationData.constants.LETTERS_DIVISION_DISPLAYED };
                filtersList[LibraryApplicationData.constants.LEVELS] = { code: LibraryApplicationData.constants.LEVELS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.LEVELS'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.LEVELS_SELECTED'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".LEVELS_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_APPROACH_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], tagsToSelectLabel: $translate.instant("librarySubFilters.LEVELS_TO_SELECT"), tagsInTotalLabel: null };
                filtersList[LibraryApplicationData.constants.TYPE] = { code: LibraryApplicationData.constants.TYPE, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.TYPE'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.TYPE'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".TYPE_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_APPROACH_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], tagsToSelectLabel: null, tagsInTotalLabel: null, typeAlreadySelectedClass: null };
                filtersList[LibraryApplicationData.constants.STATUS] = { code: LibraryApplicationData.constants.STATUS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.STATUS'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.STATUS'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".STATUS_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_STATUS_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], status: statusList[0], tagsToSelectLabel: null, tagsInTotalLabel: null, statusList: statusListForFrontEnd };
                filtersList[LibraryApplicationData.constants.APPROACH] = { code: LibraryApplicationData.constants.APPROACH, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.SOFT_TECH'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.APPTOACH_SOFT_TECH'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".APPROACH_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_APPROACH_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], competence: competenceList[0], tagsToSelectLabel: null, tagsInTotalLabel: null, approachList: competenceListForFrontEnd };
                filtersList[LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS] = { code: LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.COMPETENCES'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.COMPETENCES_SELECTED'), exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".COMPETENCES_EXAMPLE"), headerSubTitle: null, hasLettersDivision: true, indexLetter: 0, atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_COMPETENCE_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], selectedTechSkill: [], selectedSoftSkill: [], tagsToSelectLabel: null, tagsInTotalLabel: null, lettersDivisionContainer: LibraryApplicationData.constants.LETTERS_DIVISION_DISPLAYED };
                filtersList[LibraryApplicationData.constants.STREAMS] = { code: LibraryApplicationData.constants.STREAMS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.STREAM'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.STREAMS_SELECTED'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".STREAM_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_APPROACH_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], tagsToSelectLabel: $translate.instant("librarySubFilters.STREAMS_TO_SELECT"), tagsInTotalLabel: null };
                filtersList[LibraryApplicationData.constants.LANG] = { code: LibraryApplicationData.constants.LANG, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.LANGS'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.LANGS_SELECTED'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".LANGS_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_LANGS_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], tagsToSelectLabel: $translate.instant("librarySubFilters.LANGS_TO_SELECT"), tagsInTotalLabel: null };
                filtersList[LibraryApplicationData.constants.ADMIN_TAGS] = { code: LibraryApplicationData.constants.ADMIN_TAGS, headerTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.ADMIN_TAGS'), selectedTitle: $translate.instant(INDEX_TRANSLATION_FILTERS + '.ADMIN_TAGS_SELECTED'), headerSubTitle: null, exampleLabel: $translate.instant(INDEX_TRANSLATION_FILTERS + ".ADMIN_TAGS_EXAMPLE"), atLeastOneSelected: false, noContentFoundLabel: "librarySubFilters.NO_APPROACH_FOUND", isExpanded: false, containerClass: null, list: [], selectedFilters: [], tagsToSelectLabel: $translate.instant("librarySubFilters.ADMIN_TAGS_TO_SELECT"), tagsInTotalLabel: null };

                searchParams = {
                    searchedText: '',
                    myActivities: false,
                    forMe: false,
                    sorting: ITEM_SORTING[0],
                    openedTab: 0,
                    openedSubTabLibrary: 0,
                    openedSubTabLibraryFilters: 0,
                    forceSearchSuggestedItems: false,
                    forceMyActivitiesSearch: false,
                    forceForMeSearch: false,
                    subTabForMeSelected: 0,
                    subTabSelected: 0,
                    subTabTemplatesSelected: 0
                }
            }
        }

        // Imposta i titoli e sottotitoli ai filtri
        this.setTabsTranslations = (): void => {
            let searchId = $stateParams.searchId = $stateParams.searchId && $stateParams.searchId > 0 ? $stateParams.searchId : 0;
            if (searchId && searchId > 0) {
                sessionStorageObj = SessionStorageService.getObject(searchId);
            }
            if (sessionStorageObj && sessionStorageObj.filters) {
                if (sessionStorageObj.filters[LibraryApplicationData.constants.ARGUMENTS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.ARGUMENTS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.CATEGORIES');
                    sessionStorageObj.filters[LibraryApplicationData.constants.ARGUMENTS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.CATEGORIES');
                    sessionStorageObj.filters[LibraryApplicationData.constants.ARGUMENTS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".CATEGORY_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.ARGUMENTS].noContentFoundLabel = "librarySubFilters.NO_CATEGORY_FOUND";
                };
                if (sessionStorageObj.filters[LibraryApplicationData.constants.CLUSTERS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.CLUSTERS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.CLUSTERS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.CLUSTERS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.CLUSTERS_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.CLUSTERS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".CLUSTERS_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.CLUSTERS].noContentFoundLabel = "librarySubFilters.NO_CLUSTER_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.CLUSTERS].tagsToSelectLabel = $translate.instant("librarySubFilters.CLUSTERS_TO_SELECT");
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.FUNCTIONAL_AREAS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.FUNCTIONAL_AREAS_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".FUNCTIONAL_AREAS_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS].noContentFoundLabel = "librarySubFilters.NO_FUNCTIONAL_AREA_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS].tagsToSelectLabel = $translate.instant("librarySubFilters.FUNCTIONAL_AREAS_TO_SELECT");
                    sessionStorageObj.filters[LibraryApplicationData.constants.FUNCTIONAL_AREAS].lettersDivisionContainer = LibraryApplicationData.constants.LETTERS_DIVISION_DISPLAYED;
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.LEVELS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.LEVELS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.LEVELS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.LEVELS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.LEVELS_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.LEVELS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".LEVELS_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.LEVELS].noContentFoundLabel = "librarySubFilters.NO_APPROACH_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.LEVELS].tagsToSelectLabel = $translate.instant("librarySubFilters.LEVELS_TO_SELECT");
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.TYPE]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.TYPE].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.TYPE');
                    sessionStorageObj.filters[LibraryApplicationData.constants.TYPE].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.TYPE');
                    sessionStorageObj.filters[LibraryApplicationData.constants.TYPE].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".TYPE_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.TYPE].noContentFoundLabel = "librarySubFilters.NO_APPROACH_FOUND";
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.STATUS]) {
                    TagManager.removeTagList(LibraryApplicationData.constants.STATUS);
                    statusListForFrontEnd[0].name = $translate.instant(INDEX_TRANSLATION_FILTERS + ".ITEM_ALREADY_VIEWED");
                    statusListForFrontEnd[1].name = $translate.instant(INDEX_TRANSLATION_FILTERS + ".ITEM_NOT_VIEWED");

                    sessionStorageObj.filters[LibraryApplicationData.constants.STATUS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.STATUS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.STATUS].statusList = statusListForFrontEnd;
                    sessionStorageObj.filters[LibraryApplicationData.constants.STATUS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.STATUS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.STATUS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".STATUS_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.STATUS].noContentFoundLabel = "librarySubFilters.NO_STATUS_FOUND";
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.APPROACH]) {
                    TagManager.removeTagList(LibraryApplicationData.constants.APPROACH);
                    competenceListForFrontEnd[0].name = $translate.instant(INDEX_TRANSLATION_FILTERS + ".S_SKILLS");
                    competenceListForFrontEnd[1].name = $translate.instant(INDEX_TRANSLATION_FILTERS + ".T_SKILLS");

                    sessionStorageObj.filters[LibraryApplicationData.constants.APPROACH].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.SOFT_TECH');
                    sessionStorageObj.filters[LibraryApplicationData.constants.APPROACH].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.APPTOACH_SOFT_TECH');
                    sessionStorageObj.filters[LibraryApplicationData.constants.APPROACH].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".APPROACH_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.APPROACH].noContentFoundLabel = "librarySubFilters.NO_APPROACH_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.APPROACH].approachList = competenceListForFrontEnd;
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.COMPETENCES');
                    sessionStorageObj.filters[LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.COMPETENCES_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".COMPETENCES_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS].noContentFoundLabel = "librarySubFilters.NO_COMPETENCE_FOUND";
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.STREAMS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.STREAMS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.STREAM');
                    sessionStorageObj.filters[LibraryApplicationData.constants.STREAMS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.STREAMS_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.STREAMS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".STREAM_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.STREAMS].noContentFoundLabel = "librarySubFilters.NO_APPROACH_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.STREAMS].tagsToSelectLabel = $translate.instant("librarySubFilters.STREAMS_TO_SELECT");
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.LANG]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.LANG].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.LANGS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.LANG].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.LANGS_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.LANG].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".LANGS_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.LANG].noContentFoundLabel = "librarySubFilters.NO_LANGS_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.LANG].tagsToSelectLabel = $translate.instant("librarySubFilters.LANGS_TO_SELECT");
                }
                if (sessionStorageObj.filters[LibraryApplicationData.constants.ADMIN_TAGS]) {
                    sessionStorageObj.filters[LibraryApplicationData.constants.ADMIN_TAGS].headerTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.ADMIN_TAGS');
                    sessionStorageObj.filters[LibraryApplicationData.constants.ADMIN_TAGS].selectedTitle = $translate.instant(INDEX_TRANSLATION_FILTERS + '.ADMIN_TAGS_SELECTED');
                    sessionStorageObj.filters[LibraryApplicationData.constants.ADMIN_TAGS].exampleLabel = $translate.instant(INDEX_TRANSLATION_FILTERS + ".ADMIN_TAGS_EXAMPLE");
                    sessionStorageObj.filters[LibraryApplicationData.constants.ADMIN_TAGS].noContentFoundLabel = "librarySubFilters.NO_APPROACH_FOUND";
                    sessionStorageObj.filters[LibraryApplicationData.constants.ADMIN_TAGS].tagsToSelectLabel = $translate.instant("librarySubFilters.ADMIN_TAGS_TO_SELECT");
                };

                // Aggiorno i dati nel sessionStorage
                SessionStorageService.saveObject($stateParams.searchId, sessionStorageObj);
            }
        }

        // Imposta il sottitolo all'header della barra dei filtri (quindi, ad esempio, la scritta "Hai già selezionato una Categoria", o "Seleziona una categoria")
        this.setHeaderSubTitle = (type: string, field: any): void => {
            let selectedItems: number = filtersList[type].selectedFilters.length;
            if (type === LibraryApplicationData.constants.ARGUMENTS) {
                if (selectedItems == 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.CATEGORY_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.CATEGORIES_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.CLUSTERS) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.CLUSTER_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.CLUSTERS_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.FUNCTIONAL_AREAS) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.FUNCTIONAL_AREA_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.FUNCTIONAL_AREAS_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.LEVELS) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.LEVEL_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.LEVELS_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.TYPE) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.TYPE_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.TYPES_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.STATUS || type === LibraryApplicationData.constants.APPROACH) {
                if (filtersList[type].atLeastOneSelected) {
                    field.headerSubTitle = "1 " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.SELECTED_STATUS');
                } else {
                    field.headerSubTitle = "0 " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.SELECTED_STATUSES');
                }
            } else if (type === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                let mergedCompetencesLength: number = filtersList[type].selectedSoftSkill.length + filtersList[type].selectedTechSkill.length;
                if (mergedCompetencesLength === 1) {
                    field.headerSubTitle = mergedCompetencesLength + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.COMPETENCE_SELECTED');
                } else {
                    field.headerSubTitle = mergedCompetencesLength + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.COMPETENCES_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.STREAMS) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.STREAM_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.STREAMS_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.LANG) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.LANG_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.LANGS_SELECTED');
                }
            } else if (type === LibraryApplicationData.constants.ADMIN_TAGS) {
                if (selectedItems === 1) {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.ADMIN_TAG_SELECTED');
                } else {
                    field.headerSubTitle = selectedItems + " " + $translate.instant(INDEX_TRANSLATION_FILTERS + '.ADMIN_TAGS_SELECTED');
                }
            }
        }

        // Verifica se il filtro richiesto esiste
        this.filterExists = (key: string): boolean => {
            if (key && key.length && filtersList[key] && filtersList.hasOwnProperty(key)) {
                return true;
            };

            return false;
        }


        // Apre un filtro
        this.expandFilters = (key: string): void => {
            if (this.filterExists(key)) {
                // Recupero l'intero filtro
                let filter = filtersList[key];

                // Chiudo tutti gli altri filtri, per non avere una sovrapposizione
                this.closeAllFilters();

                // Imposto la classe del contenitore
                filter.containerClass = 'filters-border-opened';

                // Recupero la lista di Tag
                let getTags = TagManager.getTagList(key, filter);


                getTags.then((result: any) => {
                    let isAdminMode = SessionStorageService.get("adminMode") === "true" || SessionStorageService.get("adminMode") === true;
                    filter.list = filter.list && filter.list.filter(tag => !isAdminMode ? ((<any>tag).code != LibraryApplicationData.constants.CERTIFICATE_ITEM) : true)

                    filter.isExpanded = true;
                });
            }
        }

        this.getSelectedFiltersCount = (key: string): number => {
            let counter = 0;
            if (this.filterExists(key)) {
                if (key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                    // Il filtro per competenze è la somma fra i tag tech e soft
                    counter = filtersList[key].selectedTechSkill.length + filtersList[key].selectedSoftSkill.length;
                } else if (key === LibraryApplicationData.constants.STATUS) {
                    // Il filtro per lo stato può avere solo il contatore "0" oppure "1"
                    if (filtersList[key].status.id !== 1) {
                        counter = 1;
                    }
                } else if (key === LibraryApplicationData.constants.APPROACH) {
                    // Stessa cosa per l'approccio soft/tech
                    if (filtersList[key].competence.id !== 1) {
                        counter = 1;
                    }
                } else {
                    counter = filtersList[key].selectedFilters.length;
                }
            }

            return counter;
        }

        // Controlla se un filtro è stato già stato aggiunto
        this.isFilterAlreadySelect = (key: string, filterObject: any): boolean => {
            if (this.filterExists(key)) {
                if (key === LibraryApplicationData.constants.TYPE) {
                    // Filtri per tipologia (hanno il .code al posto del .tagId in quanto sono tag locali, non recuperati quindi da remoto)
                    for (let i = 0, selectedTypesLength = filtersList[key].selectedFilters.length; i < selectedTypesLength; i++) {
                        if (filtersList[key].selectedFilters[i].code && filterObject.code && filtersList[key].selectedFilters[i].code == filterObject.code) {
                            return true;
                        }
                    }
                } else if (key === LibraryApplicationData.constants.STATUS && ((filterObject === LibraryApplicationData.constants.VIEWED && filtersList[key].status.id === statusList[1].id) || (filterObject === LibraryApplicationData.constants.NOT_VIEWED && filtersList[key].status.id === statusList[2].id))) {
                    // Stato (visto/non visto)
                    return true;
                } else if (key === LibraryApplicationData.constants.APPROACH && ((filterObject === LibraryApplicationData.constants.TECH_SKILL && filtersList[key].competence.id === competenceList[1].id) || (filterObject === LibraryApplicationData.constants.SOFT_SKILL && filtersList[key].competence.id === competenceList[2].id))) {
                    // Approccio (tech/soft)
                    return true;
                } else {
                    // Tutti gli altri filtri
                    for (let i = 0, selectedLength = filtersList[key].selectedFilters.length; i < selectedLength; i++) {
                        if (filtersList[key].selectedFilters[i].tagId == filterObject.tagId) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        // Seleziona un filtro
        this.selectFilter = (key: string, filterObject: Tag): void => {
            if (this.filterExists(key) && filterObject) {
                if (key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                    // Lista delle competenze (quindi unione fra i tag Tech e i tag Soft)
                    if (filterObject.tagType === LibraryApplicationData.constants.TECH_COMPETENCES) {
                        filtersList[key].selectedTechSkill.push(filterObject);
                    } else if (filterObject.tagType === LibraryApplicationData.constants.SOFT_COMPETENCES) {
                        filtersList[key].selectedSoftSkill.push(filterObject);
                    }
                } else if (key === LibraryApplicationData.constants.APPROACH) {
                    // Filtro per approccio (radio-box tech o soft)
                    if (this.isFilterAlreadySelect(key, filterObject)) {
                        // Se il filtro specifico è già stato selezionato, significa che devo deselezionarlo
                        this.unSelectFilter(key, filterObject);
                        return;
                    } else if (filterObject === LibraryApplicationData.constants.TECH_SKILL) {
                        // Poiché la scelta di un box comporta la deselezione dell'altro, rimuovo la classe CSS personalizzasta a quest'ultimo attributo
                        this.removeBoxSelectedClass(key, LibraryApplicationData.constants.SOFT_SKILL);
                        // E aggiorno la nuova selezione
                        filtersList[key].competence = competenceList[1];
                    } else if (filterObject === LibraryApplicationData.constants.SOFT_SKILL) {
                        // Poiché la scelta di un box comporta la deselezione dell'altro, rimuovo la classe CSS personalizzasta a quest'ultimo attributo
                        this.removeBoxSelectedClass(key, LibraryApplicationData.constants.TECH_SKILL);
                        // E aggiorno la nuova selezione
                        filtersList[key].competence = competenceList[2];
                    }
                } else if (key === LibraryApplicationData.constants.TYPE) {
                    // Filtro per tipologia
                    if (this.isFilterAlreadySelect(key, filterObject)) {
                        // Se il filtro per tipologia è già stato selezionato, significa che devo deselezionarlo
                        this.unSelectFilter(key, filterObject);

                        this.toggleFilter(key);
                        return;
                    } else {
                        filtersList[key].selectedFilters.push(filterObject);
                    }
                } else if (key === LibraryApplicationData.constants.STATUS) {
                    // Filtro per stato
                    if (this.isFilterAlreadySelect(key, filterObject)) {
                        // Se il filtro per stato è già stato selezionato, significa che devo deselezionarlo
                        this.unSelectFilter(key, filterObject);
                        return;
                    } else if (filterObject === LibraryApplicationData.constants.VIEWED) {
                        // Poiché la scelta di un box comporta la deselezione dell'altro, rimuovo la classe CSS personalizzasta a quest'ultimo attributo
                        this.removeBoxSelectedClass(key, LibraryApplicationData.constants.NOT_VIEWED);
                        // E aggiorno la nuova selezione
                        filtersList[key].status = statusList[1];
                    } else if (filterObject === LibraryApplicationData.constants.NOT_VIEWED) {
                        // Poiché la scelta di un box comporta la deselezione dell'altro, rimuovo la classe CSS personalizzasta a quest'ultimo attributo
                        this.removeBoxSelectedClass(key, LibraryApplicationData.constants.VIEWED);
                        // E aggiorno la nuova selezione
                        filtersList[key].status = statusList[2];
                    }
                } else {
                    // Tutti gli altri filtri
                    filtersList[key].selectedFilters.push(filterObject);
                }
                filtersList[key].atLeastOneSelected = true;

                this.toggleFilter(key);
            }
        }

        // Deseleziona un filtro
        this.unSelectFilter = (key: string, filterObject: any, preventExpandFilters?: any): void => {
            if (this.filterExists(key) && filterObject) {
                // Filtro per competenze (quindi somma dei tag tech e tag soft)
                if (key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                    // Rimuovo dai tech
                    if (filterObject.tagType === LibraryApplicationData.constants.TECH_COMPETENCES) {
                        for (let j = 0, selectedTechLength = filtersList[key].selectedTechSkill.length; j < selectedTechLength; j++) {
                            let currentSelectedFilter = filtersList[key].selectedTechSkill[j];
                            if (currentSelectedFilter.tagId && filterObject.tagId && currentSelectedFilter.tagId === filterObject.tagId) {
                                filtersList[key].selectedTechSkill.splice(j, 1);
                                break;
                            }
                        }
                    }
                    // Rimuovo dai soft
                    if (filterObject.tagType === LibraryApplicationData.constants.SOFT_COMPETENCES) {
                        for (let k = 0, selectedSoftLength = filtersList[key].selectedSoftSkill.length; k < selectedSoftLength; k++) {
                            let currentSelectedFilter = filtersList[key].selectedSoftSkill[k];
                            if (currentSelectedFilter.tagId && filterObject.tagId && currentSelectedFilter.tagId === filterObject.tagId) {
                                filtersList[key].selectedSoftSkill.splice(k, 1);
                                break;
                            }
                        }
                    }
                    // Verifico se ho almeno un elemento selezionato
                    if (filtersList[key].selectedSoftSkill.length === 0 && filtersList[key].selectedTechSkill.length === 0) {
                        filtersList[key].atLeastOneSelected = false;
                    }
                } else if (key === LibraryApplicationData.constants.APPROACH) {
                    // Filtro riguardante l'approccio (tech/soft)
                    filtersList[key].competence = competenceList[0];
                    filtersList[key].atLeastOneSelected = false;
                    // Rimuovo la classe CSS personalizzata per il filtro (prima) attivo
                    this.removeBoxSelectedClass(key, filterObject);
                } else if (key === LibraryApplicationData.constants.STATUS) {
                    // Filtro riguardante lo status (visto/non visto)
                    filtersList[key].status = statusList[0];
                    filtersList[key].atLeastOneSelected = false;
                    // Rimuovo la classe CSS personalizzata per il filtro (prima) attivo
                    this.removeBoxSelectedClass(key, filterObject);
                } else {
                    // Rimuovo l'elemento dai selezionati
                    for (let i = 0, selectedFiltersLength = filtersList[key].selectedFilters.length; i < selectedFiltersLength; i++) {
                        let currentSelectedFilter = filtersList[key].selectedFilters[i];
                        // Se è un vero tag recuperato da remoto avrà il .tagId, invero se è un tag locale avrà il .code
                        if ((currentSelectedFilter.tagId && filterObject.tagId && currentSelectedFilter.tagId === filterObject.tagId) || (currentSelectedFilter.code && filterObject.code && currentSelectedFilter.code === filterObject.code)) {
                            filtersList[key].selectedFilters.splice(i, 1);
                            break;
                        }
                    }

                    // Se è un filtro per tipologia e lo sto rimuovendo, devo togliere anche la classe "active" dal box rappresentante la tipologia precedentemente scelta
                    if (key === LibraryApplicationData.constants.TYPE) {
                        for (let k = 0, typesLength = filtersList[key].list.length; k < typesLength; k++) {
                            let currentType: any = filtersList[key].list[k];
                            if (currentType.code && filterObject.code && currentType.code === filterObject.code) {
                                currentType.typeAlreadySelectedClass = null;
                                break;
                            }
                        }
                    }

                    // Verifico se ho almeno un elemento selezionato
                    if (filtersList[key].selectedFilters.length == 0) {
                        filtersList[key].atLeastOneSelected = false;
                    }
                }
                // Chiudo anche la barra
                if (!preventExpandFilters) {
                    this.toggleFilter(key);
                }
            }
        }

        // Rimuove la classe personalizzata del box attivo
        this.removeBoxSelectedClass = (key: string, filterObject?: any): void => {
            if (this.filterExists(key)) {
                for (let k = 0, dataLength = filtersList[key].list.length; k < dataLength; k++) {
                    let currentFilter: any = filtersList[key].list[k];

                    // Rimuovo il filtro specifico (ad esempio il "Visto")
                    if (currentFilter.setFilterAs && filterObject && currentFilter.setFilterAs === filterObject) {
                        currentFilter.boxAlreadySelectedClass = null;
                        break;
                    }

                    // Se invece non ho specificato nulla, cancello la classe CSS a tutti
                    if (!filterObject) {
                        currentFilter.boxAlreadySelectedClass = null;
                    }
                }
            }
        }

        // Effettua il conteggio di tutti i filtri selezionati
        this.countSelectedFilters = (excludeArguments?: boolean): number => {
            let count: number = null;

            for (let key in filtersList) {
                if (key === LibraryApplicationData.constants.ARGUMENTS && excludeArguments) {
                    continue;
                }

                if (filtersList[key].selectedFilters.length) {
                    count = count + filtersList[key].selectedFilters.length;
                }

                if (filtersList[key].selectedSoftSkill && filtersList[key].selectedSoftSkill.length) {
                    count = count + filtersList[key].selectedSoftSkill.length;
                }
                if (filtersList[key].selectedTechSkill && filtersList[key].selectedTechSkill.length) {
                    count = count + filtersList[key].selectedTechSkill.length;
                }

                if (filtersList[key].status && filtersList[key].status.id && filtersList[key].status.id !== statusList[0].id) {
                    count++;
                }
                if (filtersList[key].competence && filtersList[key].competence.id && filtersList[key].competence.id !== competenceList[0].id) {
                    count++;
                }
            }
            return count;
        }

        // Verifica se nel complesso c'è almeno filtro selezionato
        this.atLeastOneFilterSelected = (): boolean => {
            let atLeastOneSelected = false;
            for (let key in filtersList) {
                if (filtersList[key].atLeastOneSelected === true) {
                    atLeastOneSelected = true;
                    break;
                }
            }

            // Verifico se è stato selezionato un approccio (tech/soft)
            /* if (filtersList[LibraryApplicationData.constants.APPROACH].competence.id !== competenceList[0].id) {
                atLeastOneSelected = true;
            } */

            // Verifico se è stato selezionato uno status (visto/ non visto)
            if (filtersList[LibraryApplicationData.constants.STATUS].status.id !== statusList[0].id) {
                atLeastOneSelected = true;
            }

            return atLeastOneSelected;
        }

        // Verifica se c'è almeno filtro selezionato
        this.atLeastOneSpecificFilterSelected = (key: string, competenceType?: string): boolean => {
            if (this.filterExists(key)) {
                let atLeastOneSelected = false;

                if (key === LibraryApplicationData.constants.APPROACH && filtersList && filtersList[LibraryApplicationData.constants.APPROACH] && filtersList[LibraryApplicationData.constants.APPROACH].competence && filtersList[LibraryApplicationData.constants.APPROACH].competence.id !== competenceList[0].id) {
                    // Verifico se è stato selezionato un approccio (tech/soft)
                    atLeastOneSelected = true;
                } else if (key === LibraryApplicationData.constants.STATUS && filtersList[LibraryApplicationData.constants.STATUS].status.id !== statusList[0].id) {
                    // Verifico se è stato selezionato uno status (visto/ non visto)
                    atLeastOneSelected = true;
                } else if (competenceType && key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS && competenceType === LibraryApplicationData.constants.TECHNICAL_SKILL) {
                    // Verifico se è stato selezionato un tag Technical Skill
                    if (filtersList[key].selectedTechSkill.length) {
                        atLeastOneSelected = true;
                    }
                } else if (competenceType && key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS && competenceType === LibraryApplicationData.constants.SOFT_SKILL) {
                    // Verifico se è stato selezionato un tag Soft Skill
                    if (filtersList[key].selectedSoftSkill.length) {
                        atLeastOneSelected = true;
                    }
                } else {
                    if (filtersList[key].atLeastOneSelected === true) {
                        atLeastOneSelected = true;
                    }
                }

                return atLeastOneSelected;
            }
        }

        this.removeClassFromAlreadySelectedTypes = (): void => {
            let typeConst: string = LibraryApplicationData.constants.TYPE;
            if (filtersList[typeConst] && filtersList[typeConst].list && filtersList[typeConst].list.length) {
                for (let i = 0, typesLenght = filtersList[typeConst].list.length; i < typesLenght; i++) {
                    let currentType: any = filtersList[typeConst].list[i];
                    currentType.typeAlreadySelectedClass = null;
                }
            }
        }

        // Rimuove tutti i filtri selezionati
        this.removeAllSelectedFilters = (key?: string, excludeArguments?: boolean): void => {
            // Se è specificato un tipo specifico tolgo i suoi filtri selezionati, altrimenti lo faccio per tutti
            if (this.filterExists(key)) {
                filtersList[key].selectedFilters.length = 0;

                if (key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                    filtersList[key].selectedTechSkill.length = 0;
                    filtersList[key].selectedSoftSkill.length = 0;
                }

                // Filtro riguardante l'approccio (tech/soft)
                if (key === LibraryApplicationData.constants.APPROACH) {
                    // Rimuovo la classe CSS personalizzata per il filtro (prima) attivo
                    this.removeBoxSelectedClass(key);
                    filtersList[key].competence = competenceList[0];
                }

                // Filtro riguardante lo status (visto/non visto)
                if (key === LibraryApplicationData.constants.STATUS) {
                    filtersList[key].status = statusList[0];
                    // Rimuovo la classe CSS personalizzata per il filtro (prima) attivo
                    this.removeBoxSelectedClass(key);
                }

                // Se sto rimuovendo i filtri per tipologia, devo assicurarmi che venga rimossa anche l'eventuale classe del filtro attivo
                if (key === LibraryApplicationData.constants.TYPE && filtersList[key].list.length) {
                    this.removeClassFromAlreadySelectedTypes();
                }

                if (key === LibraryApplicationData.constants.ARGUMENTS && excludeArguments) {
                    return;
                } else {
                    // Visto che ho cancellato tutte le selezioni, salvo il fatto che non ho più nessun filtro selezionato
                    filtersList[key].selectedFilters.length = 0;
                }

                // Visto che ho cancellato tutte le selezioni, salvo il fatto che non ho più nessun filtro selezionato
                filtersList[key].atLeastOneSelected = false;
            } else {
                Object.keys(filtersList).forEach((filter) => {
                    if (filter === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                        filtersList[filter].selectedTechSkill.length = 0;
                        filtersList[filter].selectedSoftSkill.length = 0;
                    }
                    // Filtro riguardante l'approccio (tech/soft)
                    if (filter === LibraryApplicationData.constants.APPROACH) {
                        // Rimuovo la classe CSS personalizzata per il filtro (prima) attivo
                        this.removeBoxSelectedClass(filter);
                        filtersList[filter].competence = competenceList[0];
                    }

                    // Se sto rimuovendo i filtri per tipologia, devo assicurarmi che venga rimossa anche l'eventuale classe del filtro attivo
                    if (filter === LibraryApplicationData.constants.TYPE && filtersList[filter].list.length) {
                        this.removeClassFromAlreadySelectedTypes();
                    }

                    // Filtro riguardante lo status (visto/non visto)
                    if (filter === LibraryApplicationData.constants.STATUS) {
                        // Rimuovo la classe CSS personalizzata per il filtro (prima) attivo
                        this.removeBoxSelectedClass(filter);
                        filtersList[filter].status = statusList[0];
                    }

                    if (!(filter === LibraryApplicationData.constants.ARGUMENTS && excludeArguments)) {
                        // Visto che ho cancellato tutte le selezioni, salvo il fatto che non ho più nessun filtro selezionato
                        filtersList[filter].atLeastOneSelected = false;
                        filtersList[filter].selectedFilters.length = 0;
                    }
                });
            }

            // Eseguo il toggle per chiudere la barra contenente la lista dei filtri
            this.toggleFilter();
        }

        // Chiude un filtro
        this.closeFilter = (key: string): void => {
            if (this.filterExists(key)) {
                // Rimuovo la classe CSS
                filtersList[key].containerClass = null;
                // E chiudo il blocco
                filtersList[key].isExpanded = false;
            }
        }

        // Chiude tutti i filtri
        this.closeAllFilters = (): void => {
            Object.keys(filtersList).forEach((filter) => {
                filtersList[filter].containerClass = null;
                filtersList[filter].isExpanded = false;
            });
        }

        // Esegue il toggle di un filtro, verificando il suo attuale stato (cioé se è già aperto oppure no)
        this.toggleFilter = (key?: string): void => {
            if (this.filterExists(key)) {
                if (filtersList[key].isExpanded) {
                    this.closeFilter(key);
                } else {
                    this.expandFilters(key);
                }
            } else {
                // Altrimenti significa che devo chiuderle tutte
                Object.keys(filtersList).map((objectKey, index) => {
                    this.closeFilter(objectKey);
                });
            }
        }

        // Ritorna un filtro formattato
        this.getFilter = (key: string): Filter => {
            let field: Filter = null;
            if (key && filtersList[key]) {
                // Recupero il campo dalla mappa, clonandolo per non modificare l'originale
                field = JSON.parse(JSON.stringify(filtersList[key]));
                if (!this.fromAdmin && key == LibraryApplicationData.constants.TYPE) {
                    for (let i = 0; i < filtersList[key].list.length; i++) {
                        if ((<any>filtersList[key].list[i]).code == LibraryApplicationData.constants.SURVEY) {
                            filtersList[key].list.splice(i, 1);
                            break;
                        }
                    }
                }

                // In base alla tipologia, aggiungo il sottitolo nell'header
                this.setHeaderSubTitle(key, field);
            }
            return field;
        };

        // Metodo che ritorna tutti i filtri
        this.getAllFilters = (category?: string): Filters => {
            // Recupero tutta la lista dei filtri oppure, se richiesto, una categoria in particolare
            let filters: Filters = {};
            if (category && category.length && filtersCategories && filtersCategories[category] && filtersCategories[category].length) {
                for (let i = 0, filtersLength = filtersCategories[category].length; i < filtersLength; i++) {
                    let currentCategory = filtersCategories[category][i];

                    Object.keys(filtersList).map((objectKey, index) => {
                        if (objectKey && currentCategory && objectKey === currentCategory) {
                            filters[objectKey] = filtersList[objectKey];
                        }
                    });
                }
            } else {
                filters = filtersList;
            }
            if (category == "HOME_FILTERS") {
                this.fromAdmin = false;
            } else {
                this.fromAdmin = true;
            }
            return filters;
        };

        // Metodo che ritorna tutti i parametri della ricerca
        this.searchParams = (): SearchParams => {
            return searchParams;
        };

        // Ritorna l'id dello stato
        this.getStatusId = (): number => {
            return filtersList[LibraryApplicationData.constants.STATUS].status.id;
        }

        // Ritorna l'id dell'approccio
        this.getCompetenceId = (): number => {
            return 1;
        }
    });
}